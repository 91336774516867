<template>
  <div>
    <b-card class="card-statistics">
      <app-collapse>
        <app-collapse-item
          title="Filtros"
        >
          <div
            class="mb-1 col-md-10"
          >

            <label>Credores</label>
            <v-select
              id="forma-recebimento"
              v-model="creditorsFilter"
              multiple
              :options="creditors"
              :close-on-select="false"
              class="mb-2"
            />

            <div class="row align-items-center">
              <div class="col-md-3">
                <label for="example-datepicker">Data inicial</label>
                <b-form-input
                  id="example-datepicker"
                  v-model="date.date_initial"
                  locale="pt"
                  type="date"
                  class="mb-4"
                />
              </div>
              <div class="col-md-3">
                <label for="example-datepicker">Data final</label>
                <b-form-input
                  id="example-datepicker"
                  v-model="date.date_finaly"
                  locale="pt"
                  type="date"
                  class="mb-4"
                />
              </div>
            </div>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
              @click="filter"
            >
              Filtrar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              @click="resetFilter"
            >
              Resetar filtro
            </b-button>

          </div>

        </app-collapse-item>
      </app-collapse>
    </b-card>

    <!--Lista provinda do filtro-->
    <b-card v-show="showListFiltered">
      <b-col>
        <h3>Relatório Contato</h3>
      </b-col>
      <b-card
        class="padding-0"
        padding="0"
      >
        <b-button
          variant="outline-warning"
          @click="exportFile"
        >
          Exportar excel
        </b-button>
      </b-card>
      <b-table
        small
        striped
        responsive
        :fields="fields"
        :items="itemsReport"
        :per-page="itemPerPage"
        :current-page="currentPage"
      >
        <template #cell(cliente_id)="data">
          {{ (data.item.cliente_id) }}
        </template>
        <template #cell(credor_id)="data">
          {{ (data.item.credor_id) }}
        </template>
        <template #cell(data_cadastro_confirmado)="data">
          {{ data.item.data_cadastro_confirmado ? formatTimezone(data.item.data_cadastro_confirmado): '' }}
        </template>
        <template #cell(localizacao)="data">
          {{ (data.item.localizacao) }}
        </template>
        <template #cell(nome_cliente)="data">
          {{ (data.item.nome_cliente) }}
        </template>

        <template #cell(nome_credor)="data">
          {{ (data.item.nome_credor) }}
        </template>
        <template #cell(ultimo_contato)="data">
          {{ (data.item.ultimo_contato) ? formatTimezone(data.item.ultimo_contato): '' }}
        </template>

      </b-table>
    </b-card>
    <b-pagination
      v-model="currentPage"
      :per-page="itemPerPage"
      :total-rows="totalRows"
      align="center"
      size="sm"
      class="my-0"
    />
  </div>
</template>

<script>
import {
  BCard, BFormInput, BButton, BTable, BCol, BPagination,
} from 'bootstrap-vue'
import { saveAs } from 'file-saver'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
// import AppCollapse from 'src/@core/components/app-collapse/AppCollapse.vue'
import axios from '@/../axios-auth'

const ExcelJS = require('exceljs')

export default {
  components: {
    BPagination,
    BTable,
    BCol,
    BButton,
    BCard,
    // BForm,
    BFormInput,
    vSelect,
    AppCollapse,
    AppCollapseItem,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      totalRows: 1,
      currentPage: 1,
      itemPerPage: 20,
      itemsReport: [],
      showListFiltered: false,
      itemsFilter: [],
      date: {
        date_initial: '',
        date_finaly: '',
      },
      creditors: [],
      creditorsFilter: [],
      fields: [
        { key: 'cliente_id', label: 'Cliente_ID' },
        { key: 'credor_id', label: 'credor_ID' },
        { key: 'data_cadastro_confirmado', label: 'Data Cadastro' },
        { key: 'localizacao', label: 'Localização' },
        { key: 'nome_cliente', label: 'Nome Cliente' },
        { key: 'nome_credor', label: 'Nome Credor' },
        { key: 'ultimo_contato', label: 'Último contato' },
      ],
    }
  },

  async created() {
    await axios.get('api/v1/credores/listar/false', {
      headers: {
        Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        accept: 'application/json',
      },
    }).then(res => {
      res.data.dados.map(item => {
        this.creditors.push({
          label: item.nome,
          id: item.id,
        })
      })
    })

    const filter = JSON.parse(localStorage.getItem('filterPrestContas'))
    // this.filterTo.label = filter.filterToSave
    this.creditorsFilter = filter.creditors
    this.date.date_initial = filter.dataInitial
    this.date.date_finaly = filter.dataFinaly
  },
  methods: {
    async exportFile() {
      const file = await this.getDataToExported()
      if (file) {
        saveAs(file)
      }
    },

    async getDataToExported() {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('Relatório')
      const itemsPlan = this.itemsReport

      worksheet.columns = [
        { header: 'cliente_id' },
        { header: 'credor_id' },
        { header: 'data_cadastro_confirmado' },
        { header: 'localizacao' },
        { header: 'nome_cliente' },
        { header: 'nome_credor' },
        { header: 'ultimo_contato' },
      ]

      itemsPlan.map(item => {
        worksheet.addRow([
          item.cliente_id,
          item.credor_id,
          item.data_cadastro_confirmado ? this.formatTimezone(item.data_cadastro_confirmado) : '',
          item.localizacao,
          item.nome_cliente,
          item.nome_credor,
          item.ultimo_contato ? this.formatTimezone(item.ultimo_contato) : '',
        ])
      })

      worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
        row.eachCell({ includeEmpty: true }, cell => {
          if (rowNumber === 1) {
            cell.font = {
              name: 'Calibri',
              family: 2,
              bold: true,
              size: 11,
              color: { argb: 'FFFFFF' },
            }
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'fd8426' },
            }
          }
          cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
          }
          cell.alignment = {
            horizontal: 'center',
            vertical: 'center',
          }
        })
      })

      worksheet.columns.forEach(column => {
        let dataMax = 0
        column.eachCell({ includeEmpty: true }, cell => {
          const columnLength = cell.value ? cell.value.length : 15
          if (columnLength > dataMax) { dataMax = columnLength + 5 }
        })
        column.width = dataMax < 15 ? 15 : dataMax
      })

      let blob = null

      await workbook.xlsx.writeBuffer().then(data => {
        // eslint-disable-next-line camelcase
        blob = new File([data], 'Relatório Contatos', { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      })
      return blob
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    resetFilter() {
      this.creditorsFilter = []
      // this.filterTo.label = ''
      this.date.date_initial = ''
      this.date.date_finaly = ''
      localStorage.removeItem('filterPrestContas')
    },
    async filter() {
      const cred = []
      this.creditorsFilter.map(creditor => {
        cred.push(creditor.id)
      })

      const filterDetails = {
        credor_id: cred,
        data_inicio: this.date.date_initial,
        data_final: this.date.date_finaly,
      }

      if (
        filterDetails.credor_id.length === 0
        || filterDetails.data_inicio === ''
        || filterDetails.data_final === ''
      ) {
        this.$swal({
          icon: 'error',
          title: 'Erro na seleção',
          text: 'Selecione todos os campos',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      } else {
        await axios.post('api/v1/relatorios/backoffice/listar/contatos', filterDetails, {
          headers: {
            Authorization: 'Bearer '.concat(localStorage.getItem('token')),
            accept: 'application/json',
          },
        }).then(res => {
          this.showListFiltered = true
          this.itemsReport = res.data
          this.totalRows = res.data.length
        }).catch()

        const remember = {
          // filterToSave: filterDetails.filtrarPor,
          dataInitial: filterDetails.data_inicio,
          dataFinaly: filterDetails.data_final,
          creditors: this.creditorsFilter,
        }

        localStorage.setItem('filterPrestContas', JSON.stringify(remember))
      }
    },

  },
}
</script>

<style>

</style>
